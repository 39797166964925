import React from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as historyActions from '../../../actions/history';
import * as orderActions from '../../../actions/order';
import statusEnum from '../../../enums/statusEnum';
import { getMinOrderFee, getSelectedPaymentMethod } from '../../../sagas/selectors';

import withRouter from '../../../containers/WithRouter';
import Button from '../../Button';
import GooglePayComponent from '../../GooglePayComponent';
import ApplePayButton from '../ApplePayButton';

import './CartButton.css';

function CartButton({
  router,
  isMobile,
  atCapacity,
  noStoreError,
  disabled,
  orderPreviewStatus,
  placeOrderDispatch,
  goToMenu,
}) {
  const minOrderFee = useSelector(getMinOrderFee);
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod);
  const isMinOrderFeeNeeded = !!minOrderFee;

  if (atCapacity) {
    const onChangeLocationClick = () => router.replace({ pathname: '/locations' });

    return (
      <div className="CartButton">
        <Button className="MainButton btn-primary" onClick={onChangeLocationClick}>
          Change location
        </Button>
      </div>
    );
  }

  const isOrderPreviewRequested = [statusEnum.REQUESTED, statusEnum.RELOADING].includes(
    orderPreviewStatus,
  );
  const onAddItemClick = () => goToMenu({ option: 'replace' });
  const onPlaceOrder = () => placeOrderDispatch();

  let placeOrderButton = null;
  if (selectedPaymentMethod?.methodType === 'GOOGLEPAY') {
    placeOrderButton = (
      <GooglePayComponent
        modalEnabled
        buttonSizeMode="fill"
        disabled={isOrderPreviewRequested || noStoreError || disabled}
      />
    );
  } else if (selectedPaymentMethod?.methodType === 'APPLEPAY') {
    placeOrderButton = (
      <ApplePayButton disabled={noStoreError || disabled || isOrderPreviewRequested} />
    );
  } else {
    placeOrderButton = (
      <Button
        className="btn-primary"
        loading={isOrderPreviewRequested}
        disabled={noStoreError || disabled}
        onClick={onPlaceOrder}
      >
        {isMinOrderFeeNeeded ? 'Accept min. order fee' : 'Send order'}
      </Button>
    );
  }

  return (
    <ul className="CartButton">
      <li className="MenuButton">
        <Button className="btn-teal" onClick={onAddItemClick}>
          {isMobile ? 'Add items' : 'Add more items'}
        </Button>
      </li>
      <li className="MainButton">{placeOrderButton}</li>
    </ul>
  );
}

CartButton.propTypes = {
  router: PropTypes.objectOf(PropTypes.object).isRequired,
  isMobile: PropTypes.bool,
  atCapacity: PropTypes.bool,
  noStoreError: PropTypes.string,
  disabled: PropTypes.bool,
  orderPreviewStatus: PropTypes.number.isRequired,
  placeOrderDispatch: PropTypes.func.isRequired,
  goToMenu: PropTypes.func.isRequired,
};

CartButton.defaultProps = {
  isMobile: false,
  atCapacity: false,
  noStoreError: null,
  disabled: false,
};

const mapStateToProps = (state) => ({
  orderPreviewStatus: state.order.status.orderPreview,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToMenu: historyActions.goToMenu,
      placeOrderDispatch: orderActions.placeOrderDispatch,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartButton));
