import React, { Component } from 'react';
import { connect } from 'react-redux';
import { chain } from 'lodash';
import PropTypes from 'prop-types';

import paymentMethodShape from '../../shapes/paymentMethodShape';
import userOrderShape from '../../shapes/userOrderShape';

import OrderPayment from '../../components/OrderPayment';

class OrderPaymentContainer extends Component {
  static propTypes = {
    userOrder: userOrderShape.isRequired,
    paymentMethods: PropTypes.objectOf(paymentMethodShape).isRequired,
  };

  get totalPrice() {
    const { userOrder } = this.props;

    return userOrder.total;
  }

  get discountName() {
    const { userOrder } = this.props;

    return userOrder.discountName;
  }

  get promoCode() {
    const { userOrder } = this.props;
    const { rewardClaim } = userOrder;

    if (!rewardClaim) return null;

    if (rewardClaim.source !== 'p') {
      return null;
    }

    return rewardClaim.param;
  }

  get discountTitle() {
    const { discountName, promoCode } = this;

    if (!discountName) {
      return null;
    }

    if (promoCode) {
      return `${discountName} – ${promoCode}`;
    }

    return discountName;
  }

  get discountAmount() {
    const { userOrder } = this.props;

    return userOrder.discountAmount;
  }

  get salesTax() {
    const { userOrder } = this.props;

    return userOrder.totalTax;
  }

  get surchargeInfo() {
    const { userOrder } = this.props;

    return chain(userOrder.surcharges).values().flatten().value();
  }

  get subtotal() {
    const { userOrder } = this.props;

    return userOrder.price;
  }

  get paymentMethod() {
    const { userOrder, paymentMethods } = this.props;
    const paymentRequestDetail = userOrder.paymentRequestDetails?.find(
      ({ params }) => params?.paymentMethodUuid,
    );
    const paymentMethodUuid = paymentRequestDetail?.params.paymentMethodUuid;

    return paymentMethods[paymentMethodUuid];
  }

  render() {
    const { userOrder } = this.props;

    if (!userOrder) {
      return null;
    }

    return (
      <OrderPayment
        subtotal={this.subtotal}
        surchargeInfo={this.surchargeInfo}
        totalPrice={this.totalPrice}
        discountTitle={this.discountTitle}
        discountAmount={this.discountAmount}
        salesTax={this.salesTax}
        paymentMethod={this.paymentMethod}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userOrder: state.api.userOrders[ownProps.orderId],
  paymentMethods: state.api.paymentMethods,
});

export default connect(mapStateToProps, undefined)(OrderPaymentContainer);
