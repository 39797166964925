import React from 'react';
import PropTypes from 'prop-types';

import paymentMethodShape from '../../shapes/paymentMethodShape';

import iconGooglePay from '../Cart/SelectPaymentMethod/google-pay-mark_800.svg';
import iconApplePay from '../Cart/SelectPaymentMethod/icon-apple-pay.svg';
import FontAwesome from '../FontAwesome';
import OrderPaymentLine from '../OrderPaymentLine';
import SectionSeparator from '../SectionSeparator';

import './OrderPayment.css';

function OrderPayment(props) {
  const {
    subtotal,
    surchargeInfo,
    totalPrice,
    discountTitle,
    discountAmount,
    salesTax,
    paymentMethod,
  } = props;

  const showSubtotal = surchargeInfo.length > 0 || discountAmount > 0;
  if (!paymentMethod) return null;

  let iconElement;
  let displayText;

  if (paymentMethod.methodType === 'GOOGLEPAY') {
    iconElement = (
      <img alt="Google Pay mark" className="GooglePayIcon icon-align" src={iconGooglePay} />
    );
    displayText = 'GOOGLE PAY';
  } else if (paymentMethod.methodType === 'APPLEPAY') {
    iconElement = <img alt="Apple Pay mark" className="ApplePayIcon" src={iconApplePay} />;
    displayText = 'APPLE PAY';
  } else {
    iconElement = <FontAwesome className="CardIcon" name="credit-card" />;
    displayText = `${paymentMethod.cardBrand} ${paymentMethod.label}`;
  }

  return (
    <div className="OrderPayment">
      <div className="OrderPaymentTitle">
        <SectionSeparator typographyColor="secondary.dark" className="SharpGroteskSmBold15">
          Payment
        </SectionSeparator>
        <div className="paymentMethodLabel">
          {iconElement}
          {displayText}
        </div>
      </div>
      <div className="surcharges">
        {showSubtotal && <OrderPaymentLine amount={subtotal}>Subtotal</OrderPaymentLine>}
        {surchargeInfo.map((surcharge) => (
          <OrderPaymentLine key={surcharge.surchargeType} amount={surcharge.price}>
            {surcharge.description}
          </OrderPaymentLine>
        ))}
      </div>
      {discountAmount > 0 && (
        <div className="discounts">
          <OrderPaymentLine amount={discountAmount} negative>
            {discountTitle}
          </OrderPaymentLine>
        </div>
      )}
      <OrderPaymentLine amount={totalPrice} tax={salesTax} typographyVariant="subtitle1" total>
        Total
      </OrderPaymentLine>
    </div>
  );
}

OrderPayment.propTypes = {
  subtotal: PropTypes.number.isRequired,
  surchargeInfo: PropTypes.arrayOf(PropTypes.object),
  totalPrice: PropTypes.number.isRequired,
  discountTitle: PropTypes.string,
  discountAmount: PropTypes.number.isRequired,
  salesTax: PropTypes.number.isRequired,
  paymentMethod: paymentMethodShape,
};

OrderPayment.defaultProps = {
  surchargeInfo: [],
  discountTitle: null,
  paymentMethod: null,
};

export default OrderPayment;
