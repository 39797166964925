import PropTypes from 'prop-types';

const allowedCardBrands = ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA'];

const cardBrandValidator = (props, propName, componentName) => {
  const value = props[propName];
  if (!value) {
    return null;
  }

  const normalizedValue = value.toLowerCase();
  const allowedValues = allowedCardBrands.map((brand) => brand.toLowerCase());

  if (!allowedValues.includes(normalizedValue)) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`,
    );
  }

  return null;
};

const paymentMethodShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  methodType: PropTypes.oneOf(['CARD', 'STORED_VALUE', 'GOOGLEPAY', 'APPLEPAY']).isRequired,
  cardBrand: cardBrandValidator,
  label: PropTypes.string,
  balance: PropTypes.number,
});

export default paymentMethodShape;
