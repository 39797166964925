import { includeState } from './includeState';

export const UPDATE_GGLOCATION = 'UPDATE_GGLOCATION';
export const RESET_GGLOCATION = 'RESET_GGLOCATION';
export const GGLOCATION_ERROR = 'GGLOCATION ERROR';
export const RESET_ADDRESS = 'RESET_ADDRESS';

export const UPDATE_TIME_SLOT = 'UPDATE_TIME_SLOT';
export const SELECT_ADDRESS = 'SELECT_ADDRESS';
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const UPDATE_SCHEDULER = 'UPDATE_SCHEDULER';

export const UPDATE_ORDER_ORDER_ITEM = 'UPDATE_ORDER_ORDER_ITEM';
export const MODIFY_ORDER_ORDER_ITEM = 'MODIFY_ORDER_ORDER_ITEM';
export const UPDATE_INGREDIENT_BREAKDOWNS = 'UPDATE_INGREDIENT_BREAKDOWNS';
export const REMOVE_ORDER_ITEM = 'REMOVE_ORDER_ITEM';
export const REMOVE_ORDER_ITEMS = 'REMOVE_ORDER_ITEMS';
export const REMOVE_ORDER_ITEM_DISPATCH = `${REMOVE_ORDER_ITEM}_DISPATCH`;
export const UPDATE_ORDER_ITEM_QUANTITY = 'UPDATE_ORDER_ITEM_QUANTITY';
export const ADD_UNAVAILABLE_ITEMS = 'ADD_UNAVAILABLE_ITEMS';
export const REMOVE_UNAVAILABLE_ITEMS = 'REMOVE_UNAVAILABLE_ITEMS';

export const SHOW_CART = 'SHOW_CART';
export const HIDE_CART = 'HIDE_CART';

export const SET_ORDER_TYPE = 'SET_ORDER_TYPE';
export const SET_ORDER_TIME = 'SET_ORDER_TIME';
export const SET_DINING_CHOICE = 'SET_DINING_CHOICE';
export const SET_LANDING_PAGE = 'SET_LANDING_PAGE';

export const ENABLE_CARBON_OFFSET = 'ENABLE_CARBON_OFFSET';
export const DISABLE_CARBON_OFFSET = 'DISABLE_CARBON_OFFSET';

export const ENABLE_TAKE_OUT_WITH_BAG = 'ENABLE_TAKE_OUT_WITH_BAG';
export const DISABLE_TAKE_OUT_WITH_BAG = 'DISABLE_TAKE_OUT_WITH_BAG';

export const PLACE_ORDER = 'PLACE_ORDER';
export const PLACE_ORDER_DISPATCH = `${PLACE_ORDER}_DISPATCH`;
export const PLACE_ORDER_REQUESTED = `${PLACE_ORDER}_REQUESTED`;
export const PLACE_ORDER_SUCCESS = `${PLACE_ORDER}_SUCCESS`;
export const PLACE_ORDER_ERROR = `${PLACE_ORDER}_ERROR`;

export const RESET_ORDER = 'RESET_ORDER';

export const REPLACE_ORDER = 'REPLACE_ORDER';
export const REPLACE_ORDER_DISPATCH = `${REPLACE_ORDER}_DISPATCH`;
export const REPLACE_ORDER_SUCCESS = `${REPLACE_ORDER}_SUCCESS`;
export const REPLACE_ORDER_ERROR = `${REPLACE_ORDER}_ERROR`;

export const ADD_REPLACE_ORDER_ERROR = 'ADD_REPLACE_ORDER_ERROR';

export const OPEN_CART_AFTER_TIME_SELECTED = 'OPEN_CART_AFTER_TIME_SELECTED';
export const OPEN_MENU_ITEM_PAGE_AFTER_TIME_SELECTED = 'OPEN_MENU_ITEM_PAGE_AFTER_TIME_SELECTED';

export const SAVE_BEFORE_SIGN_IN_ORDER_PRICE = 'SAVE_BEFORE_SIGN_IN_ORDER_PRICE';

export const AWAIT_ORDER_PRICE = 'AWAIT_ORDER_PRICE';
export const DELAY_ORDER = 'DELAY_ORDER';
export const ROLLBACK_AWAITING_ORDER = 'ROLLBACK_AWAITING_ORDER';

export const ORDER_PREVIEW = 'ORDER_PREVIEW';
export const ORDER_PREVIEW_DISPATCH = `${ORDER_PREVIEW}_DISPATCH`;
export const ORDER_PREVIEW_REQUESTED = `${ORDER_PREVIEW}_REQUESTED`;
export const ORDER_PREVIEW_SUCCESS = `${ORDER_PREVIEW}_SUCCESS`;
export const ORDER_PREVIEW_ERROR = `${ORDER_PREVIEW}_ERROR`;

export const UPDATE_PRICES = 'UPDATE_PRICES';

export const CREATE_UPSELL_MENU_ITEMS = 'CREATE_UPSELL_MENU_ITEMS';

export const HANDLE_PLACE_ORDER_APPLE_PAY = 'HANDLE_PLACE_ORDER_APPLE_PAY';

export const updateGglocation = ({ gglocation, timeSlots }) => ({
  type: UPDATE_GGLOCATION,
  gglocation,
  timeSlots,
});

export const resetGglocation = () => ({
  type: RESET_GGLOCATION,
});

export const gglocationError = () => ({
  type: GGLOCATION_ERROR,
});

export const updateTimeSlot = (id) => ({
  type: UPDATE_TIME_SLOT,
  id,
});
export const resetAddress = () => ({
  type: RESET_ADDRESS,
});

export const updateScheduler = ({ gglocationId, processingTime, cutoffTime }) => ({
  type: UPDATE_SCHEDULER,
  gglocationId,
  processingTime,
  cutoffTime,
});

export const selectAddress = ({ customerAddressId }) => ({
  type: SELECT_ADDRESS,
  customerAddressId,
});

export const selectPaymentMethod = (paymentMethod) => ({
  type: SELECT_PAYMENT_METHOD,
  paymentMethod,
});

export const updateOrderOrderItem = ({ orderItem }) => ({
  type: UPDATE_ORDER_ORDER_ITEM,
  orderItem,
});

export const modifyOrderOrderItem = ({ orderItemId }) => ({
  type: MODIFY_ORDER_ORDER_ITEM,
  orderItemId,
});

export const updateIngredientBreakdowns = (ordermenu) => ({
  type: UPDATE_INGREDIENT_BREAKDOWNS,
  ordermenu,
});

export const removeOrderItem = (orderItemId) => ({
  type: REMOVE_ORDER_ITEM,
  orderItemId,
});

export const removeOrderItems = (orderItemIds) => ({
  type: REMOVE_ORDER_ITEMS,
  orderItemIds,
});

export const removeOrderItemDispatch = (orderItemId) => ({
  type: REMOVE_ORDER_ITEM_DISPATCH,
  orderItemId,
});

export const updateOrderItemQuantity = (orderItemId, quantity) =>
  includeState({
    action: {
      type: UPDATE_ORDER_ITEM_QUANTITY,
      orderItemId,
      quantity,
    },
  });

export const addUnavailableItems = (items) => ({
  type: ADD_UNAVAILABLE_ITEMS,
  items,
});

export const removeUnavailableItems = (apiIds) => ({
  type: REMOVE_UNAVAILABLE_ITEMS,
  apiIds,
});

export const showCart = () => ({
  type: SHOW_CART,
});

export const hideCart = () => ({
  type: HIDE_CART,
});

export const setOrderType = (orderType) => ({
  type: SET_ORDER_TYPE,
  orderType,
});

export const setOrderTime = (orderTime) => ({
  type: SET_ORDER_TIME,
  orderTime,
});

export const setDiningChoice = ({ diningChoice, isStationFlow = false }) => ({
  type: SET_DINING_CHOICE,
  diningChoice,
  isStationFlow,
});

export const setLandingPage = (landingPage) => ({
  type: SET_LANDING_PAGE,
  landingPage,
});

export const enableCarbonOffset = () => ({
  type: ENABLE_CARBON_OFFSET,
});

export const disableCarbonOffset = () => ({
  type: DISABLE_CARBON_OFFSET,
});

export const enableTakeOutWithBag = () => ({
  type: ENABLE_TAKE_OUT_WITH_BAG,
});

export const disableTakeOutWithBag = () => ({
  type: DISABLE_TAKE_OUT_WITH_BAG,
});

export const placeOrderDispatch = () => ({
  type: PLACE_ORDER_DISPATCH,
});

export const placeOrder = () => ({
  type: PLACE_ORDER_REQUESTED,
});

export const resetOrder = () => ({
  type: RESET_ORDER,
});

export const replaceOrderDispatch = ({ userOrderId }) => ({
  type: REPLACE_ORDER_DISPATCH,
  userOrderId,
});

export const openCartAfterTimeSelected = () => ({
  type: OPEN_CART_AFTER_TIME_SELECTED,
});

export const openMenuItemPageAfterTimeSelected = () => ({
  type: OPEN_MENU_ITEM_PAGE_AFTER_TIME_SELECTED,
});

export const awaitOrderPrice = () => ({
  type: AWAIT_ORDER_PRICE,
});

export const delayOrder = () => ({
  type: DELAY_ORDER,
});

export const rollbackAwaitingOrder = () => ({
  type: ROLLBACK_AWAITING_ORDER,
});

export const saveBeforeSignInOrderPrice = () => ({
  type: SAVE_BEFORE_SIGN_IN_ORDER_PRICE,
});

export const previewOrderDispatch = () => ({
  type: ORDER_PREVIEW_DISPATCH,
});

export const previewOrder = () => ({
  type: ORDER_PREVIEW_REQUESTED,
});

export const updatePrices = ({
  discountName,
  discountSource,
  orderItemPrices,
  orderItemIds,
  orderItemHashPrices,
  orderPreviewCurrentOrderItem,
  pricing,
  stages,
}) => ({
  type: UPDATE_PRICES,
  discountName,
  discountSource,
  orderItemPrices,
  orderItemIds,
  orderItemHashPrices,
  orderPreviewCurrentOrderItem,
  pricing,
  stages,
});

export const createUpsellMenuItems = (menuItemIds) => ({
  type: CREATE_UPSELL_MENU_ITEMS,
  menuItemIds,
});

export const addReplaceOrderErrorOrderId = ({ userOrderId }) => ({
  type: ADD_REPLACE_ORDER_ERROR,
  userOrderId,
});

export const handlePlaceOrderApplePay = (applePaySession) => ({
  type: HANDLE_PLACE_ORDER_APPLE_PAY,
  applePaySession,
});
