import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import GooglePayButton from '@google-pay/button-react';
import { Typography } from '@mui/material';
import { Buffer } from 'buffer';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as orderActions from '../../actions/order';
import { brandNameEnum } from '../../enums/brandEnum';
import * as selectors from '../../sagas/selectors';

import { CLIENT_BRAND } from '../../version';
import Button from '../Button';
import Modal from '../Modal';

import * as settings from '../../settings';

import './GooglePayComponent.css';

function GooglePayComponent({ modalEnabled, buttonSizeMode, disabled }) {
  const finalPrice = useSelector(selectors.getOrderTotalPrice);
  const defaultPaymentMethod = useSelector(selectors.getUserDefaultPaymentMethod);
  const selectedPaymentMethod = useSelector(selectors.getSelectedPaymentMethod);
  const paymentMethods = useSelector(selectors.getPaymentMethods);
  const applePayPaymentMethod = useSelector(selectors.getApplePaymentMethod);

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const applePayMethod = paymentMethods.find((method) => method.methodType === 'APPLEPAY');

  const isProduction = process.env.NODE_ENV === 'production';

  const googlePayMerchantId = isProduction
    ? settings.GOOGLEPAY_PRODUCTION_MERCHANT_ID
    : '12345678901234567890';

  // const environment = isProduction ? 'PRODUCTION' : 'TEST';
  const environment = 'TEST';

  const paymentRequestData = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY'],
          allowedCardNetworks: ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: '2c2p',
            gatewayMerchantId: settings.MERCHANT_ID_2C2P,
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: googlePayMerchantId,
      merchantName: brandNameEnum[CLIENT_BRAND.toLowerCase()],
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: String(finalPrice),
      currencyCode: settings.CURRENCY_CODE,
      countryCode: settings.COUNTRY_CODE,
    },
    callbackIntents: ['PAYMENT_AUTHORIZATION'],
  };

  const handleLoadPaymentData = (paymentRequest) => {
    const paymentToken = paymentRequest.paymentMethodData.tokenizationData.token;
    const encodedToken = Buffer.from(paymentToken).toString('base64');

    const paymentMethodWithToken = {
      ...selectedPaymentMethod,
      paymentMethodToken: encodedToken,
    };

    dispatch(orderActions.selectPaymentMethod(paymentMethodWithToken));

    dispatch(orderActions.placeOrderDispatch());
  };

  const handlePaymentAuthorized = () => {
    // Add additional payment authorization if required
    if (disabled) {
      return { transactionState: 'ERROR' };
    }
    return { transactionState: 'SUCCESS' };
  };

  const handleReadyToPayChange = (result) => {
    if (!result.isButtonVisible || !result.isReadyToPay) {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);

    if (
      window.ApplePaySession &&
      window.ApplePaySession.canMakePayments() &&
      applePayPaymentMethod?.id
    ) {
      dispatch(orderActions.selectPaymentMethod(applePayMethod));
    } else if (defaultPaymentMethod) {
      dispatch(orderActions.selectPaymentMethod(defaultPaymentMethod));
    } else {
      dispatch(orderActions.selectPaymentMethod({ id: 'add' }));
    }
  };

  const handleOnClick = (event) => {
    if (disabled) {
      event.preventDefault();
    }
  };

  return (
    <div className={classnames('google-pay-container', { disabled })}>
      <GooglePayButton
        buttonType="short"
        environment={environment}
        paymentRequest={paymentRequestData}
        onLoadPaymentData={handleLoadPaymentData}
        onPaymentAuthorized={handlePaymentAuthorized}
        buttonSizeMode={buttonSizeMode}
        onReadyToPayChange={handleReadyToPayChange}
        onClick={handleOnClick}
        style={{ width: '100%', height: '100%' }}
      />
      <Modal className="modal-small" show={showModal && modalEnabled} onHide={handleCloseModal}>
        <Modal.Body className="kale">
          <Typography variant="calafia2" color="primary">
            Oops! We&apos;re sorry...
          </Typography>
          <p>
            Google Pay service is currently not available. Please use a different payment method.
          </p>
          <Button className="btn-primary" onClick={handleCloseModal}>
            Okay
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

GooglePayComponent.propTypes = {
  modalEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonSizeMode: PropTypes.string,
};

GooglePayComponent.defaultProps = {
  modalEnabled: false,
  disabled: false,
  buttonSizeMode: 'static',
};

export default GooglePayComponent;
