const brandEnum = Object.freeze({
  SALADSTOP: 'gg',
  HEYBO: 'bowl',
});

export const brandNameEnum = Object.freeze({
  gg: 'SaladStop!',
  bowl: 'Heybo',
});

export default brandEnum;
