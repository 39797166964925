import { removeError } from '@spq/redux-api-client';
import { parseBackendOrder, userOrdersGglocations } from '@spq/redux-api-client-ecom';
import { rehydrateEmpty, rehydrateReducer, rehydrateStatus, toCamelCaseKeys } from '@spq/utils';
import { REHYDRATE } from 'redux-persist/lib/constants';

import * as apiActions from '../actions/api';
import * as loyaltyActions from '../actions/loyalty';
import * as pageActions from '../actions/page';
import * as userActions from '../actions/user';
import pusherOperationEnum from '../enums/pusherOperationEnum';
import statusEnum from '../enums/statusEnum';
import * as foodUtils from '../utils/foodUtils';

import packageInfo from '../../package.json';

import ecomService, {
  auth2Service,
  cloudFrontService,
  loyaltyService,
  oauthService,
  paymentService,
} from '../services/api';
import * as settings from '../settings';

const createStatus = () => {
  const status = {};
  const endpointNames = [
    ...cloudFrontService.endpointNames,
    ...loyaltyService.endpointNames,
    ...paymentService.endpointNames,
    ...auth2Service.endpointNames,
    ...oauthService.endpointNames,
    ...ecomService.endpointNames,
  ];
  endpointNames.forEach((name) => {
    status[name] = statusEnum.INITIAL;
  });

  return status;
};

const createPageInfo = () => {
  const status = {};
  const endpointNames = ['orders', 'favoriteMenuItems'];
  endpointNames.forEach((name) => {
    status[name] = {
      count: null,
      previous: null,
      next: null,
      currentPage: null,
    };
  });

  return status;
};

const defaultState = Object.freeze({
  userOrders: {},
  userOrderIds: [],
  mostFrequentGglocationIds: [],
  mostRecentGglocationIds: [],
  paymentMethods: {},
  paymentMethodIds: [],
  defaultPaymentMethod: null,
  applePayPaymentMethod: null,
  favoriteMenuItems: {},
  favoriteMenuItemIds: [],
  menuItemFavoriteMenuItemId: {},
  rewardUuids: [],
  rewards: {},
  menuItems: {},
  menuItemIngredientServings: {},
  menuGroups: {},
  apiIdMenuItemNames: {},
  ingredientCategories: {},
  nutrients: {},
  ingredientGroups: {},
  bases: {},
  menuCategories: {},
  cyoSections: {},
  tags: {},
  ingredients: {},
  ingredientNutrientAmounts: {},
  servingNumberVariations: {},
  settings: {},
  gglocationMenuGroups: {},
  preferenceGroups: {},
  pricingVariations: {},
  gglocations: {},
  storeIds: [],
  partnerIds: [],
  timeSlots: {},
  imageData: {
    images: {},
    gglocationsImages: {},
  },
  storeBusyDetails: {},
  upsellCategories: {},
  status: createStatus(),
  pageInfo: createPageInfo(),
  errors: [],
  version: packageInfo.version,
});

// eslint-disable-next-line default-param-last
export default function apiReducer(state = defaultState, action) {
  switch (action.type) {
    case REHYDRATE: {
      const incoming = action.payload && action.payload.api;
      if (incoming && incoming.version === state.version) {
        return Object.freeze({
          ...rehydrateStatus(
            [
              `${cloudFrontService.gglocationsImages}`,
              `${cloudFrontService.images}`,
              `${cloudFrontService.ingredients}`,
              `${cloudFrontService.gglocationMenuGroups}`,
              `${cloudFrontService.menuGroups}`,
              `${cloudFrontService.bases}`,
              `${cloudFrontService.menuCategories}`,
              `${cloudFrontService.nutrients}`,
              `${cloudFrontService.ingredientCategories}`,
              `${cloudFrontService.ingredientGroups}`,
              `${cloudFrontService.tags}`,
              `${cloudFrontService.cyoSections}`,
              `${cloudFrontService.servingNumberVariations}`,
              `${cloudFrontService.settings}`,
              `${cloudFrontService.pricingVariations}`,
            ],
            state,
            incoming,
          ),
          ...rehydrateReducer(
            [
              'userOrders',
              'userOrderIds',
              'mostRecentGglocationIds',
              'paymentMethods',
              'paymentMethodIds',
              'defaultPaymentMethod',
              'applePayPaymentMethod',
              'favoriteMenuItems',
              'favoriteMenuItemIds',
              'menuItemFavoriteMenuItemId',
              'rewardUuids',
              'rewards',
              'menuItems',
              'menuItemIngredientServings',
              'menuGroups',
              'gglocations',
              'storeIds',
              'partnerIds',
              'timeSlots',
              'imageData',
              'bases',
              'tags',
              'ingredients',
              'ingredientNutrientAmounts',
              'menuCategories',
              'ingredientCategories',
              'ingredientGroups',
              'nutrients',
              'cyoSections',
              'servingNumberVariations',
              'pricingVariations',
              'gglocationMenuGroups',
              'apiIdMenuItemNames',
              'settings',
              'upsellCategories',
            ],
            state,
            incoming,
          ),
          pricingVariations: rehydrateEmpty(state.pricingVariations, incoming.pricingVariations),
          ingredients: rehydrateEmpty(state.ingredients, incoming.ingredients),
          menuItems: foodUtils.updateMenuItems(state.menuItems, incoming.menuItems),
          menuItemIngredientServings: {
            ...state.menuItemIngredientServings,
            ...incoming.menuItemIngredientServings,
          },
          status: {
            ...state.status,
            rewards: incoming.status.rewards,
            paymentMethod: incoming.status.paymentMethod,
            orders: incoming.status.orders,
          },
          pageInfo: incoming.pageInfo || state.pageInfo,
        });
      }
      return Object.freeze({ ...state });
    }

    // action comes from Pusher not the endpoints
    case userActions.ADD_ORDER: {
      const response = toCamelCaseKeys(action.response);
      let { userOrders, userOrderIds, userOrderMenuItems } = parseBackendOrder(
        response,
        settings.COUNTRY_NAME,
        settings.COUNTRY_CODE_ALPHA3,
      );
      userOrders = { ...state.userOrders, ...userOrders };
      userOrderIds = [...state.userOrderIds, ...userOrderIds];
      userOrderMenuItems = { ...state.userOrderMenuItems, ...userOrderMenuItems };
      const userOrdersList = userOrderIds.map((userOrderId) => userOrders[userOrderId]);

      const { mostFrequentGglocationIds, mostRecentGglocationIds } =
        userOrdersGglocations(userOrdersList);

      return Object.freeze({
        ...state,
        userOrders,
        userOrderIds,
        mostFrequentGglocationIds,
        mostRecentGglocationIds,
        userOrderMenuItems,
      });
    }

    /* Action comes from Pusher not the endpoints */
    case userActions.UPDATE_ORDER: {
      const response = toCamelCaseKeys(action.response);

      const userOrder = {
        ...response,
        id: response.uuid,
      };

      return Object.freeze({
        ...state,
        userOrders: {
          ...state.userOrders,
          [userOrder.id]: {
            ...state.userOrders[userOrder.id],
            orderStatusId: userOrder.orderStatusId,
            modified: userOrder.modified,
            pickupTime: userOrder.ready || userOrder.due,
            estimatedDelivery: userOrder.estimatedDelivery || userOrder.ready || userOrder.due,
            cancellationRemark: userOrder.cancellationRemark,
            deliveryInfo: userOrder.deliveryInfo,
          },
        },
        status: {
          ...state.status,
          orders: statusEnum.SUCCESS,
        },
      });
    }

    /* Update action comes from Pusher not the endpoints */
    case apiActions.UPDATE_STORE_BUSY_FROM_PUSHER: {
      const updatedStoreBusy = toCamelCaseKeys(action.object);

      return Object.freeze({
        ...state,
        storeBusyDetails: {
          ...state.storeBusyDetails,
          [updatedStoreBusy.storeId]: updatedStoreBusy,
        },
      });
    }

    case apiActions.UPDATE_DISABLED_INGREDIENT_FROM_PUSHER: {
      const ingredient = toCamelCaseKeys(action.ingredient);
      const gglocationId = `${ingredient.gglocationType}_${ingredient.gglocationId}`;
      const disabledIngredients = state.gglocationDisabledIngredients[gglocationId] || [];

      /* Update disabled ingredient list based on operation type */
      const updatedIngredients =
        action.operation === pusherOperationEnum.ENABLED
          ? disabledIngredients.filter((id) => id !== ingredient.ingredientId)
          : [...disabledIngredients, ingredient.ingredientId];

      return Object.freeze({
        ...state,
        gglocationDisabledIngredients: {
          ...state.gglocationDisabledIngredients,
          [gglocationId]: updatedIngredients,
        },
      });
    }

    case userActions.SIGN_OUT: {
      return Object.freeze({
        ...state,
        favoriteMenuItems: {},
        favoriteMenuItemIds: [],
        paymentMethodIds: [],
        paymentMethods: {},
        defaultPaymentMethod: defaultState.defaultPaymentMethod,
        applePayPaymentMethod: defaultState.applePayPaymentMethod,
        userOrders: {},
        userOrderIds: [],
        mostRecentGglocationIds: [],
        rewardUuids: defaultState.rewardUuids,
        rewards: defaultState.rewards,
        status: {
          ...state.status,
          paymentMethod: statusEnum.INITIAL,
          orders: statusEnum.INITIAL,
          verifyRecaptcha: statusEnum.INITIAL,
        },
      });
    }
    case pageActions.CLOSE_SCANNER_MODAL: {
      // Reset scanCode api status and error when close
      return Object.freeze({
        ...state,
        errors: removeError(state.errors, 'scanCode'),
        status: {
          ...state.status,
          scanCode: statusEnum.INITIAL,
        },
      });
    }
    case loyaltyActions.SCAN_CODE_ERROR:
      return Object.freeze({
        ...state,
        errors: [...state.errors, action.error],
      });
    case apiActions.CLEAR_API_ENDPOINT_ERRORS:
      return Object.freeze({
        ...state,
        errors: removeError(state.errors, action.endpointName),
      });
    default:
      return state;
  }
}
